
 

  
$(document).ready(function() {  


  jQuery.validator.setDefaults({
    debug: false,
    success: "valid"
  });
  
  $.validator.addMethod("agreeterms", function(value, element) {
    if (element.is(':checked')) { 
      return true; 
    }
    else {
      return false;
    }
  }, "You must agree to the terms. ");  

  $.validator.addMethod("youtube", function(value, element) {
    if (value === '') { 
      return true; // blank is ok i guess, so it is validated when not required
    }
    var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return (value.match(p)) ? RegExp.$1 : false;
  }, "Enter correct URL");

  $.validator.addMethod("pg", function(value, element) {
    if (value === '') { 
      return true; // blank is ok i guess, so it is validated when not required
    }
    var words = ['anal', 'anus','arse', 'ass', 'ballsack', 'balls', 'bastard', 'bitch', 'biatch', 'bloody', 'blowjob', 'blow job', 'bollock', 'bollok', 'boner', 'boob', 'bugger', 'bum', 'butt', 'buttplug', 'clitoris', 'cock', 'coon', 'crap', 'cunt', 'damn', 'dick', 'dildo', 'dyke', 'fag', 'feck', 'fellate', 'fellatio', 'felching', 'fuck', 'f u c k', 'fudgepacker', 'fudge packer', 'flange', 'Goddamn', 'God damn', 'hell', 'homo', 'jerk', 'jizz', 'knobend', 'knob end', 'labia', 'lmao', 'lmfao', 'muff', 'nigger', 'nigga', 'omg', 'penis', 'piss', 'poop', 'prick', 'wtf', 'pube', 'pussy', 'queer', 'scrotum', 'sex', 'shit', 's hit', 'sh1t', 'slut', 'smegma', 'spunk', 'tit', 'tosser', 'turd', 'twat', 'vagina', 'wank', 'whore', 'wtf' ];
    var clean = false;
    if (words.indexOf( value ) === -1) { clean = true; }
    //console.log(clean);
    //else clean = false;                        
    return ( clean );
  }, "That's not allowed!");

	$(".commentform").validate({
  		rules: {
  			comment: {
  				pg: true,
          required: true,
  			},
        email: {
  				email: true,
          required: true,
        }
  		},
  		messages: {
  			comment: {
          pg: "No bad words, please.",
          required: "Please fill in this field",          
        },
  			email: {
          email: "Type a proper email address please.",
          required: "Please fill in this field",          
        },        
      }
	});



  $(".location-form").validate({
    rules: {
      email: true,
      host_website: {
          url: true,
        },
      agree_to_terms: {
        agreeterms: true,
        required: true
      },             
    },
    messages: {
      agree_to_terms: {
        agreeterms: "You must agree to the terms. ",
        required: "You must agree to the terms. "
      },    
      required: "Please fill in this field",
      host_website: {
          url: "Please enter a valid URL (must start with http:// or https://)",
        }       
    }
  });
  
  $("#teamform").validate({
    rules: {
      email: true,
      agree_to_terms: {
        agreeterms: true,
        required: true
      },             
    },
    messages: {
      agree_to_terms: {
        agreeterms: "You must agree to the terms. ",
        required: "You must agree to the terms. "
      },    
      required: "Please fill in this field",      
    }
  });  

	// validate signup form on keyup and submit
	$(".youtubeform").each(function() {
    $(this).validate({
  		rules: {
  			video_url: {
  				required: true,
  				youtube: true
  			}
  		},
  		messages: {
  			video_url: {
          required: "Please enter a valid Youtube URL",
          youtube: "Please enter a valid Youtube URL such as: https://www.youtube.com/watch?v=qybUFnY7Y8w"
        }
      },
      submitHandler: function(form) {
        $("#parental-agreement").modal('show');
        $("#parental-agreement-no").attr('data-id', $(form).attr('id'));
        $("#parental-agreement-yes").click(function() {
          form.submit();
        });
      }     
	  });                   
  });
  
/*  $("#edit-images").each(function() {
    $(this).validate({ 
  		rules: {
  			team_upload: {
  				required: true
  			}
  		},    
      submitHandler: function(form) {
        $(".image-upload-spinner").show();
        $("#parental-agreement").modal('show');
        $("#parental-agreement-yes").click(function() {
          form.submit();
        });
      } 
    });
  });*/  

  $.validator.addMethod("no_calc", function(value, element) {
    if (value === '') { 
      return true; // blank is ok i guess, so it is validated when not required
    }
    return ($('#team_footprint_volume_no_calc').val() === $('#team_footprint_volume').val());
  }, "Incorrect volume");

	$("#worksheetform").validate({
  		rules: {
  			'team_footprint[height]': {
  				required: true,
  				number: true,
          min: 0
  			},
  			'team_footprint[area]': {
  				required: true,
  				number: true,
          min: 0
  			},
  			'team_footprint[volume]': {
  				required: true,
  				number: true,
          min: 0
  			}, 
        'team_footprint[volume_no_calc]': {
          no_calc: true,
  				number: true,
          min: 0
        }               
  		},
  		messages: {
  			'team_footprint[height]': {
          required: "Please enter a height",
          number: "Please enter a number",
          min: "Negative height not allowed"
        },
  			'team_footprint[area]': {
          required: "Please enter an area",
          number: "Please enter a number",
          min: "Negative area not allowed"
        },
  			'team_footprint[volume]': {
          required: "Please enter a volume",
          number: "Please enter a number",
          min: "Negative volume not allowed"
        },
  			'team_footprint[volume_no_calc]': {
          no_calc: "Incorrect volume based on your area and height.",        
          number: "Please enter a number",
          min: "Negative volume not allowed"
        }                          
      }
	});                  

	$("#edit-answers").validate({
  		rules: {
  			team_favorite: {
  				youtube: true
  			},
        team_website: {
          url: true
        },
        team_facebook: {
          url: true
        }
  		},
  		messages: {
  			team_favorite: {
          youtube: "Please enter a valid Youtube URL such as: https://www.youtube.com/watch?v=qybUFnY7Y8w"
        },
        team_website: "Please enter a valid URL (must start with http:// or https://)",
        team_facebook: "Please enter a valid URL (must start with http:// or https://)"
      }
	});    
  
	$("#license-apply").validate({
    rules: {
      email: true,
    },
    message: {
      required: "Please fill in this field",
    }
	});          

/*$("#edit-profile-pic").submit(function(e) {
  e.preventDefault();
  $("#parental-agreement").modal('show');
  $("#parental-agreement-yes").attr('data-id', $(this).attr('id'));
});*/ 

$("#parental-agreement-no").click(function() {
  button_attr = $("#parental-agreement-no").attr('data-id');
  if (button_attr === "edit-images") {
    anchor_attr = "_team_image_id";
    query_str = "must_agree_images";
  }
  if (button_attr === "edit-profile-pic") {
    anchor_attr = "_thumbnail_id";
    query_str = "must_agree_profilepic";    
  }
  if (button_attr === "edit-video-walkthrough") {
    anchor_attr = "_team_video_walkthrough";
    query_str = "must_agree_vid_wt";
  }
  if (button_attr === "edit-video-run-1") {
    anchor_attr = "_team_video_run_1";
    query_str = "must_agree_vid_run";    
  }     
  window.location.href = window.location.protocol + "//" + window.location.host + window.location.pathname + '?action=' + query_str + '#' + anchor_attr;
});


$("#parental-agreement-yes").click(function() {
  if ($("#parental-agreement-yes").attr('data-id') === 'edit-images') {
    $(".images-upload-spinner").show();  
    $("#edit-images").submit();
  }
  if ($("#parental-agreement-yes").attr('data-id') === 'edit-profile-pic') {
    $(".images-upload-spinner").show();
    $("#edit-profile-pic").submit();
  }  
});

$("#team_upload").change(function() {
  //$(".image-upload-spinner").show();
  $("#parental-agreement").modal('show');
  $("#parental-agreement-no").attr('data-id', $(this).attr('data-id'));
  $("#parental-agreement-yes").attr('data-id', $(this).attr('data-id'));  
});

$(".images-upload-button").change(function() {
  //$(".images-upload-spinner").show();
  $("#parental-agreement").modal('show');
  $("#parental-agreement-no").attr('data-id', $(this).attr('data-id'));
  $("#parental-agreement-yes").attr('data-id', $(this).attr('data-id'));    
});  

$(".delete-image-form").submit(function() {
  var c = confirm("Are you sure you want to delete this image?");
  return c; //you can just return c because it will be true or false
});
$(".delete-video-form").submit(function() {
  var c = confirm("Are you sure you want to delete this video?");
  return c; //you can just return c because it will be true or false
});


  $(document).on("click", '.footprint_cell', function(e) {
    //alert('hey')
    var x=0;
    var y=0;
    if ( $(this).hasClass('filled') ) {
      $(this).removeClass('filled');
    }
    else {
      $(this).addClass('filled');
    }
      
    var current = $(this).attr('id');
    //$('#team_footprint_grid').val(current);      
    
    var fullgrid = [];
    var area=0;
    if($('#team_footprint_metric').is(':checked')) { 
      each_area = 0.09;
    }    
    else {
      each_area = 1;
    }
    
    $('.filled').each(function(){
      fullgrid.push( $(this).attr('id') );
      area += each_area;
    });
    
    fullgrid.toString();
    $('#team_footprint_grid').val(fullgrid);
    
    if($('#team_footprint_metric').is(':checked')) { 
      //area = area * 30.764;
      area = Math.round( area * 100 ) / 100;
    }
    $('#team_footprint_area').val(area);
    var height = $('#team_footprint_height').val();
    if (height > 0) {
      $('#team_footprint_volume').val(Math.round(area*height*100)/100);
    }
  });

});



$(document).ready(function() {
  $(document).on("change", '#team_footprint_height', function() {
    var height = $('#team_footprint_height').val();
    var area = $('#team_footprint_area').val();
    if (height > 0 && area > 0) {
      $('#team_footprint_volume').val(Math.round(area*height*100)/100);  
    }
  });


  $("#team_footprint_measure input:radio").change(function () {
    if ( $(this).val() === 'imperial' ) {
      unit = 'ft';
      each_area = 1;      
    }
    if ( $(this).val() === 'metric' ) {
      unit = 'm';
      each_area = 0.09;      
    }
    
    $('.system_of_units').each(function(){
      $(this).html(unit);
    });
    
    var area=0;  
    $('.filled').each(function(){
      area += each_area;
    });
    
    if (unit === 'm') { 
      //area = area / 10.764;
      area = Math.round( area * 100 ) / 100;
    }
    $('#team_footprint_area').val(area);
    
    var height = $('#team_footprint_height').val();
       
    if (height > 0 && area > 0) {
      $('#team_footprint_volume').val(Math.round(area*height*100)/100);
    }
                 
  });  
  


$(document).on("click", '.print-worksheet-button', function() {
  window.print();
}); 
     


  $(document).on("click", '.ballot-hide', function() {
    $('.voting-sidebar').addClass('hidden');
    $('.voting-sidebar-minimized').removeClass('hidden');
  });  
  $(document).on("click", '.ballot-show', function() {
    $('.voting-sidebar').removeClass('hidden');
    $('.voting-sidebar-minimized').addClass('hidden');
  }); 




  $(document).on("click", '.show-edit', function() {
    var data_id = $(this).attr("data-id");
    $('#'+data_id).removeClass('hidden');
    setTimeout(function(){
      $('#'+data_id).focus();
    }, 500);
    $(this).addClass('hidden');
  });





isoLangs = {
    'af' : 'Afrikaans',
    'sq' : 'Albanian',
    'ar' : 'Arabic',
    'hy' : 'Armenian',
    'az' : 'Azerbaijani',
    'eu' : 'Basque',
    'be' : 'Belarusian',
    'bn' : 'Bengali',
    'bs' : 'Bosnian',
    'bg' : 'Bulgarian',
    'ca' : 'Catalan',
    'ceb' : 'Cebuano',
    'zh-CN' : 'Chinese',
    'zh-TW' : 'Chinese(Traditional)',
    'hr' : 'Croatian',
    'cs' : 'Czech',
    'da' : 'Danish',
    'nl' : 'Dutch',
    'en' : 'English',
    'eo' : 'Esperanto',
    'et' : 'Estonian',
    'tl' : 'Filipino',
    'fi' : 'Finnish',
    'fr' : 'French',
    'gl' : 'Galician',
    'ka' : 'Georgian',
    'de' : 'German',
    'el' : 'Greek',
    'gu' : 'Gujarati',
    'ht' : 'Haitian',
    'ha' : 'Hausa',
    'iw' : 'Hebrew',
    'hi' : 'Hindi',
    'hmn' : 'Hmong',
    'hu' : 'Hungarian',
    'is' : 'Icelandic',
    'ig' : 'Igbo',
    'id' : 'Indonesian',
    'ga' : 'Irish',
    'it' : 'Italian',
    'ja' : 'Japanese',
    'jw' : 'Javanese',
    'kn' : 'Kannada',
    'km' : 'Khmer',
    'ko' : 'Korean',
    'lo' : 'Lao',
    'la' : 'Latin',
    'lv' : 'Latvian',
    'lt' : 'Lithuanian',
    'mk' : 'Macedonian',
    'ms' : 'Malay',
    'mt' : 'Maltese',
    'mi' : 'Maori',
    'mr' : 'Marathi',
    'mn' : 'Mongolian',
    'ne' : 'Nepali',
    'no' : 'Norwegian',
    'fa' : 'Persian',
    'pl' : 'Polish',
    'pt' : 'Portuguese',
    'pa' : 'Punjabi',
    'ro' : 'Romanian',
    'ru' : 'Russian',
    'sr' : 'Serbian',
    'sk' : 'Slovak',
    'sl' : 'Slovenian',
    'so' : 'Somali',
    'es' : 'Spanish',
    'sw' : 'Swahili',
    'sv' : 'Swedish',
    'ta' : 'Tamil',
    'te' : 'Telugu',
    'th' : 'Thai',
    'tr' : 'Turkish',
    'uk' : 'Ukranian',
    'ur' : 'Urdu',
    'vi' : 'Vietnamese',
    'cy' : 'Welsh',
    'yi' : 'Yiddish',
    'yo' : 'Yoruba',
    'zu' : 'Zulu'
  };

  $(document).on("click", '.flag', function() {
    class_list = this.className.split(/\s+/);
    lang_code = class_list[2];
    $('#current-language').html(isoLangs[lang_code]);
  });

});
